:root {

  --dialog-offset-y: 2vw;
  --dialog-offset-x: 2vw;

  --width-container-dense:1000px;

  --ease: 0.2s all  cubic-bezier(0.075, 0.82, 0.165, 1);

  --shadow: 0 0 7px 7px rgba(0,0,0,0.01);

  --pretty-colour: #F6C0F6;
}

/* STANDARDIZE CSS */

* {
  margin:0;
  padding:0;
  color:#333333;
  font-family: 'Inter', sans-serif;
}

::selection {
  background:rgba(161, 215, 254, 0.47);
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Links */

a {
  text-decoration:none;
}

.subtle-link:hover {
  color:#1f1f1f;
}

.button {
  text-decoration:none;
  color:inherit;
  font-family: "Inter", sans-serif;
  font-weight:600;
  font-size:0.86rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding:15px 25px;
  border-radius:4px;
  transition:var(--ease);
}

.button.outlined {
  border:1px solid #EEEEEE;
} .button.outlined:hover,
.button.outlined.active {
  background:#F8F8F8;
  box-shadow: var(--shadow);
}

.button.solid {
  background:#333333;
  color:#EEEEEE;
} .button.solid:hover,
.button.solid.active {
  background:#2c2c2c;
  box-shadow: var(--shadow);
}

.button.text {
  padding:0;
  margin:15px 25px;
}
.button.text svg {
  height:18px;
  transition:var(--ease);
} .button.text:hover > svg {
  padding-left:2px;
}


/* CSS */

header {
  width:100%;
  box-sizing: border-box;
  border-bottom: 1px solid #EEEEEE;
  padding:30px 2vw;
  display: flex;
  align-items:center;
  justify-content: space-between;

  background: hsla(0,0%,100%,.85);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

header .header-text {
  font-family:"Halant", sans-serif;
  font-weight:700;
  color:#333333;
  font-size:1.2rem;
}

header .header-action {
  display: flex;
}

header .header-action .button:last-child {
  margin-left: 1vw;
} 

header.dense {
  width:var(--width-container-dense);
  margin:0 auto;
  border-bottom:none;
  padding:30px 0;
}

sup {
  font-size:0.7rem;
  /* padding:3px 5px; */
  border-radius:4px;
  color:#e282e2;
}

/* Landing Page */

.dev-notes {
  margin:0 auto;
  width:var(--width-container-dense);
  box-sizing: border-box;
  padding:20px;
  background:#f6c0f6;
}

.feedback-bubble {
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  z-index: 10000;
  background-color: #f6c0f6;
  box-sizing:border-box;
  padding: 15px 20px;
  border-radius: 50px;
  cursor: pointer;
  transition:var(--ease);
  display:flex;
  flex-direction:column;
  align-items:center;
}

.feedback-bubble h3 {
  font-size: 0.8rem;
}

.feedback-bubble p {
  height:0;
  display:flex;
  align-items:flex-end;
  transition:var(--ease);
  overflow:hidden;
  font-size: 0.7rem;
}

.feedback-bubble:hover {
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
}

.feedback-bubble:hover > p {
  height:18px;
  box-sizing: border-box;
}

.feedback-card {
  transition:var(--ease);
  display:flex;
  position: fixed;
  align-items:center;
  bottom:calc(3vw + 80px);
  right:0;
  max-width:230px;
  overflow:hidden;
  transform: translateX(100%);
  box-shadow: 0 0 10px rgba(0,0,0,0.05);
  border-radius: 50px 0 0 50px;
}
.feedback-card .feedback-inner {
  box-sizing: border-box;
  padding:30px 30px 30px 30px;
  background:white;
  border-radius: 50px 0 0 50px;
}
.feedback-card p {
  font-size:0.8rem;
}
.feedback-card textarea {
  resize: none;
}
.feedback-card.open {
  transform: translateX(0);
}

.elephant {
  width:var(--width-container-dense);
  margin:0 auto;
  box-sizing: border-box;
  padding:6vh 0 10vh 0;
}

.elephant h1 {
  font-family: "Halant", sans-serif;
  font-size:3rem;
  line-height:120%;
  max-width:500px;
}

.elephant h3 {
  font-family: "Halant", sans-serif;
  font-weight:600;
  font-size:1.5rem;
  color:#777777;
  padding:20px 0;
}

.elephant .elephant-actions {
  display:flex;
  padding-top:4vh;
}

.testimonials {
  width:100%;
  box-sizing: border-box;
  padding:6vh 0;
  background:#333333;
}

.testimonial-inner {
  width:var(--width-container-dense);
  display:flex;
  margin:4vh auto;
  justify-content: space-between;
  align-items: center;
}

.testimonial-inner * {
  color:#EEEEEE;
}

.testimonial-inner h2 {
  line-height:140%;
  font-weight:600;
}

.testimonial-inner .left p {
  line-height:170%;
  padding-top:3vh;
}

.testimonial-inner .left {
  width:60%;
}

.stats {
  display:flex;
  padding-top:5vh;
}

.stats h5 {
  font-family: "Halant", serif;
  font-size:3rem;
}

.stats h6 {
  font-weight:400;
  font-size:1rem;
  color:#777777;
}

.stats .users {
  padding-right:4vw;
}

.testimonial-inner .right {
  display:flex;
  flex-direction:column;
  align-items:flex-end;
  justify-content:space-between;
  max-width:400px;
}

.testimone {
  background:#393939;
  box-sizing: border-box;
  padding:20px;
  width:90%;
  border-radius:4px;
}

.testimone .name {
  font-family: "Halant", serif;
  font-size:1.4rem;
  padding:10px 0 20px 0;
}

.testimone p {
  line-height:150%;
  padding-bottom:10px;
}

.testimone {
  margin-bottom:3vh;
}

.testimone:last-child {
  margin-bottom:0;
}

section {
  padding:5vh 0;
}

section .copy {
  width:60%;
}

section h2 {
  line-height:150%;
  font-size:2rem;
}

section p {
  line-height:170%;
  padding-top:3vh;
}

.streaks-inner {
  width:var(--width-container-dense);
  margin:0 auto;
  padding:4vh 0;
}

section .action {
  padding-top:3vh;
  display:table;
}

.badge-preview {
  display:flex;
  padding-top:4vh;
  flex-wrap:wrap;
  justify-content:space-between;
}



footer {
  width:100%;
  background:#EEEEEE;
  box-sizing: border-box;
}

.login-screen footer,
.register-screen footer {
  margin-top:5vh;
}

footer .footer-inner {
  padding:6vw 0 calc(6vw - 30px) 0;
  display:flex;
  flex-direction:column;
  align-items:center  ;
  justify-content: center;;
}

footer a.header-text {
  font-family: "Halant", sans-serif;
  font-size:1.2rem;
  font-weight:700;
}

footer a.minor {
  color:#CCCCCC;
  padding-top:10px;
} footer a.minor:hover {
  color:#bbbbbb;
}

footer .barrel {
  text-align:center;
  color:#777777;
  padding-bottom:20px;
  font-size:0.9rem;
}

/* For register/login/badges/non-member screen content */
main { 
  width:var(--width-container-dense);
  margin:0 auto;
  box-sizing: border-box;
  padding:6vh 0;
}

h2 {
  font-family: "Halant", sans-serif;
  font-size:2rem;
  font-weight:700;
}

label {
  display:table;
  padding-top:20px;
  padding-bottom:5px;
  font-weight:600;
}

input[type="text"],
input[type="password"] {
  box-sizing: border-box;
  padding:10px 12px;
  border-radius:4px;
  border:none;
  background:#EEEEEE;
  font-size:1rem;
  transition:var(--ease);
  outline:none;
  border:1px solid transparent;
}

input[type="text"]:hover,
input[type="password"]:hover {
  background:#e6e6e6;
}

input[type="text"]:focus,
input[type="password"]:focus {
  box-shadow: var(--shadow);
  border:1px solid #CCCCCC;
}

.form-action {
  margin-top:24px;
  display:flex;
  align-items:center;
}

button {
  display:table;
  padding:15px 20px;
  font-size:0.86rem;
  color:#EEEEEE;
  font-weight:600;
  background:#333333;
  border:none;
  border-radius:4px;
  cursor:pointer;
}
button:disabled {
  color:#777;
  cursor:default;
}

.form-action a {
  margin-left:20px;
  font-weight:500;
  color:#b6b6b6;
  transition:var(--ease);
}
.form-action a:hover {
  color:#969696;
}

.form-notification {
  margin-top:24px;
  box-sizing: border-box;
  padding:20px;
  border-radius:4px;
  display:table;
  width:100%;
}

.form-notification.error {
  background:brown;
  color:#EEEEEE;
}

.form-notification.info {
  background:rgb(42, 91, 165);
  color:#EEEEEE;
}



.container-tight {
  box-sizing: border-box;
  max-width:40vw;
  min-width:calc(500px + 10vh);
  padding:10vh 0;
  margin:0 auto;
}

.container-tight h2 {
  font-family:"Halant", sans-serif;
  font-weight:700;
  padding-bottom:3vh;
  font-size:1.3rem;
  height:45px;
}

.editor-wrapper{
  min-height:calc(77vh - 45px);
}

.editor-wrapper.font--times-new-romans * {
  font-family:'Times New Romans', serif;
  font-size:1.1rem;
} .editor-wrapper.font--inter * {
  font-family:'Inter', sans-serif;
  font-size:1rem;
} .editor-wrapper.font--inconsolata * {
  font-family:'Inconsolata', monospace;
  font-size:1rem;
}

.entry-page {
  z-index:0;
  position:relative;
}

.entry-page .prompter {
  color:#CCCCCC;
  height:0;
  line-height: 1.42;
  z-index:-1;
  position:absolute;
  user-select:none;
}

.entry-page .quill,
.entry-page .ql-container {
  min-height:100%; 
}

.entry-page .ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  min-height: 100%;
  outline: none;
  overflow-y: auto;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  z-index:2;
}

.entry-page .ql-clipboard {
  left: -100000px;
  height: 1px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}

.entry-page .word-count {
  position:fixed;
  bottom:var(--dialog-offset-y);
  right:var(--dialog-offset-x);
  color:#CCCCCC;
  cursor:pointer;
  font-size:0.9rem;
}

.entry-page .word-count:hover,
.entry-page .word-count.opened {
  color:#A1A1A1;
}

.entry-page .word-count-dialog {
  position:fixed;
  bottom: calc(var(--dialog-offset-y) + 30px);
  right: var(--dialog-offset-x);
  box-sizing: border-box;
  border: 1px solid #EEEEEE;
  background:#F8F8F8;
  font-size:0.9rem;
  border-radius:4px;
}

.entry-page .dialog-line {
  padding:15px 15px;
  cursor:pointer;
}

.entry-page .dialog-line:hover {
  background:#eeeeee;
}

.entry-page .more-menu {
  position:fixed;
  bottom:var(--dialog-offset-y);
  left:var(--dialog-offset-x);
  cursor:pointer;
}

.entry-page .more-menu:hover > svg,
.entry-page .more-menu.opened > svg {
  stroke: #A1A1A1;
  fill: #A1A1A1;  
}

.entry-page .menu-dialog {
  position:fixed;
  bottom: calc(var(--dialog-offset-y) + 40px);
  left: var(--dialog-offset-x);
  box-sizing: border-box;
  border: 1px solid #EEEEEE;
  background:#F8F8F8;
  font-size:0.9rem;
  border-radius:4px;
}

.entry-page .menu-dialog .menu-dialog-top {
  display:flex;
  flex-direction: column;
  border-bottom: 1px solid #EEEEEE;
}

.entry-page .dialog-line.link {
  display: flex;
  align-items:center;
}

.entry-page .dialog-line.link svg {
  margin-right:10px;
  height:18px;
}

.entry-statistics {
  padding-bottom:5vh;
  margin-bottom:5vh;
  border-bottom: 1px solid #EEEEEE;
}

.entry-statistics .numbers {
  /* max-width:500px; */
  display:flex;
  flex-wrap:wrap;
}

.entry-statistics .stat-item {
  margin-right:3vw;
  width:150px;
}

.option-block {
  padding:20px 0 10px 0;
}

.toggle-container {
  display:flex;
  padding-top:20px;
  align-items:center;
  width:auto;
}

.toggle-container .toggle-value {
  padding-left:15px;
}

.custom-toggle {
  cursor:pointer;
  width:50px;
  border-radius:20px;
  background:#EEEEEE;
  box-sizing: border-box;
  cursor:pointer;
}

.custom-toggle .toggle-slider {
  padding:5px;
  width:100%;
  box-sizing: border-box;
  display:flex;
}

.custom-toggle .toggle-dot {
  height:20px;
  width:20px;
  background:#CCCCCC;
  border-radius:100%;
}

/* right = active/yes/affirmative/neh */
.custom-toggle.right .toggle-slider {
  padding-left:25px;
}

.custom-toggle.dark.right {
  background:#161616;
}



/* Home */

main.home {
  display: flex;
}

main.home .left{
  min-width:210px;
}

main.home .right {
  box-sizing: border-box;
  padding-left:4vw;
}

.today,
.profile,
.badges {
  padding-top:1.5vw;
}

main.home h3 {
  font-family: "Halant", sans-serif;
  font-size:1.3rem;
  padding-top:10px;
}

.inline-text-link {
  display:table;
  color:rgb(53, 165, 240);
  margin-top:10px;
  font-weight:500;
  cursor:pointer;
  transition: var(--ease);
}

.inline-text-link:hover {
  color:rgb(47, 144, 209);
}

.med-text {
  padding-top:1vh;
}
.column  {
  padding:0.2vh 0 0.8vh 0;
}

.today .section-inner {
  display:flex;
  box-sizing: border-box;
  padding:10px 0;
}

.today .section-inner .column {
  padding-right:30px;
}
.today .section-inner .column:last-child {
  padding-right:0;
}

.today .column .big-text {
  font-size:1.4rem;
  font-weight:600;
}

.circologram {
  display:flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-top:20px;
}

.circologram .circle-container {
  box-sizing: border-box;
  padding:10px 10px 10px 0;
}

.circologram .circle {
  height:30px;
  width:30px;
  border-radius:100%;
  background:#EEEEEE;
  text-align:center;
  cursor:pointer;
  transition:var(--ease);
}

.circologram .circle:hover {
  background:#DADADA;
}

.circologram .circle.filled {
  background:rgba(45,173,0,0.4);
}
.circologram .circle.filled:hover {
  background:rgba(45,173,0,0.6);
}

.circologram .label {
  width:0;
  white-space: nowrap;
  margin-left: -100%;
  margin-top:10px;
  height:0;

  visibility:hidden;
}

.circologram .circle:hover ~ .label {
  visibility:visible;
}

.view-entry * {
  word-break: break-word;
}

.view-entry.font--times-new-romans * {
  font-family:'Times New Romans', serif;
  font-size:1.1rem;
  line-height: 1.42;
} .view-entry.font--inter * {
  font-family:'Inter', sans-serif;
  font-size:1rem;
  line-height: 1.42;
} .view-entry.font--inconsolata * {
  font-family:'Inconsolata', monospace;
  font-size:1rem;
  line-height: 1.42;
}

/* Mini badges section */
.badges-inner {
  display:flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding:1.5vh 0;
}
.mini-badge {
  width:50px;
  height:50px;
  cursor: pointer;
  margin:0 10px 10px 0;
}
.mini-badge img {
  height:100%;
  width:100%;
  border-radius:100%;
}
.mini-badge .mini-badge-hover {
  line-height:140%;
  width:100px;
  text-align:center;
  background:hsla(0,0%,100%,.85);
  backdrop-filter:blur(10px);
  padding:7px 10px;
  border-radius:4px;
  font-size:0.8rem;
  transform:translateX(-50%);
  margin-left:50%;
  visibility:hidden;
}
.mini-badge:hover > .mini-badge-hover {
  visibility:visible;
}


/* Stats Screen */

.stats-container {
  width:100%;
  display:flex;
  justify-content:space-between;
}

.stats-container .col {
  /* width:32%; */
  width:49%;
  box-sizing: border-box;
}

.stats-container h4 {
  font-weight:500;
  color:#777;
}

.stat-item {
  margin-top:3vh;
}

.stat-item h5 {
  font-size:2rem;
  font-family:"Halant", sans-serif;
}

.stat-item p {
  font-weight:600;
  font-size:0.95rem;
}


/* Account Utility Screens (pass reset) */

.intensity {
  width:100%;
}

.intensity .header {
  font-family: "Halant", sans-serif;
  font-weight:700;
  text-align:center;
  width:100%;
  box-sizing: border-box;
  padding:7vh 0;
  font-size: 1.2rem;
}

.intensity h4 {
  padding-bottom:2vh;
}

.intensity .header a {
  font-family: "Halant", sans-serif;
}

.intensity .action-box {
  margin:0 auto;
  width:400px;
  box-sizing: border-box;
  padding:35px;
  border:1px solid #EEEEEE;
}

.intensity .action-box input {
  width:100%;
}

.intensity .action-box .button {
  margin-top:20px;
}

.intensity .action-notification {
  margin-top:20px;
  font-weight:bold;
}

.intensity input {
  margin: 2vh 0;
}


/* Badges style */

.badges-container {
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
}

.badge {
  width:48%;
  margin-bottom:3vh;
  display:flex;
  align-items:center;
}

.badge img {
  margin-right:1vw;
  height:80px;
  width:80px;
  border-radius:100%;
  background:#EEEEEE;
  overflow:hidden;
}

.badge .contents {
  max-width:calc(100% - 80px - 1vw);
}

.badge .badge-name {
  font-weight:600;
  margin-bottom:5px;
  font-size:1rem;
  
  /* font-family:"Halant", sans-serif;  */
}










.only-mobile {
  display:none;
}

/* Media Query */

@media only screen and (max-width: 1000px) {
  .only-mobile {
    display:table;
  }

  :root {
    --width-container-dense:100%;
  }

  .container-tight {
    min-width:100%;
    padding:5vw;
  }

  header,
  header.dense,
  .elephant,
  main {
    padding-right: 5vw;
    padding-left:5vw;
  }

  main input,
  .form-notification {
    width:100%;
  }

  .header-action a.button.outlined.shazam {
    display:none;
  }

  footer .footer-inner {
    padding:10vh 0;
  }
}

/* Mobile */
@media only screen and (max-width: 600px) {

  main.home {
    flex-direction:column;
  }
  main.home .left,
  main.home .right {
    width:100%;
    padding-left:0;
  }
  
  .today,
  .profile,
  .badges {
    padding-top:4vh;
  }

  main.home .right {
    padding-top:4vh;
  }
  
  main.home .right h3 {
    padding-left:0;
  }
  
  .circologram {
    padding-top:10px;
  }

  .stats-container {
    flex-direction:column;
  }

  .stats-container .col {
    width:100%;
    padding-top:5vh;
  }
  .stats-container .col:first-child {
    padding-top:0;
  }

  .testimonial-inner {
    flex-direction:column;
    box-sizing: border-box;
    padding:0 5vw;
  }
  
  .testimonial-inner .left,
  .testimonial-inner .right {
    width:100%;
  }

  .testimonial-inner .right {
    max-width:100%;
  }

  .testimone {
    margin:3vh 0 0 0;
    min-width:100%;
  }

  section .copy {
    width:100%;
    box-sizing: border-box;
    padding: 0 5vw;
  }

  .badge-preview {
    flex-direction: column;
  }
  
  .badge-preview .badge {
    width:100%;
  }
  .badge-preview .badge img {
    margin-right:4vw;
  }

  .badges-container {
    flex-direction:column;
  }

  .badge {
    width:100%;
  }
  .badge img {
    margin-right:4vw;
  }
}